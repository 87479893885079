@media screen and (width <= 1024px) {
  body {
    font-size: 2em;
  }

  #templatemo_menu {
    z-index: 1000;
    background: url("templatemo_menu.4fcc897b.png") no-repeat;
    width: 100%;
    height: 75px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  #templatemo_menu ul {
    float: right;
    background: #faebd7;
    width: 100px;
    margin: 0;
    padding: 0 40px 0 0;
    list-style: none;
  }

  #templatemo_menu ul li {
    float: left;
    background: none;
    width: 164px;
    height: 45px;
    margin: 0;
    padding: 0;
    display: block;
  }

  #templatemo_menu ul li a {
    float: left;
    width: 150px;
    height: 75px;
    padding-right: 4px;
    font-size: 16px;
    line-height: 75px;
    display: block;
  }

  #templatemo_menu ul li a:hover {
    background: url("templatemo_menu_hover.8df707ef.png") no-repeat;
  }

  #templatemo_menu .last {
    background: none;
  }

  div#templatemo_header {
    clear: both;
    background: url("templatemo_header.5c8e10a2.png") top no-repeat;
    width: 100%;
    height: 170px;
  }

  div#templatemo_header div#site_title {
    float: none;
    width: 300px;
    height: 170px;
    margin: 0 auto;
  }

  @media screen and (width <= 768px) {
    body {
      font-size: 1.5em;
    }
  }
}

/*# sourceMappingURL=about.0147a028.css.map */
