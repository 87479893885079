/*on device smallewr than 1024px set font-size to 2em*/
@media screen and (max-width: 1024px) {
	body {
		font-size: 2em;
	}
   /* on a container div with id templatemo_menu we have a ul with 5 li inside, on is li there is an a. 
   create a mobile style dropdown menu with three dots on right side of screen, 
   on click have to open the menu and show th li/a elements in a vertical list.
   */
    #templatemo_menu {
        width: 100%;
        height: 75px;
        margin-top: 40px;
        margin-bottom: 20px;
        background: url(images/templatemo_menu.png) no-repeat;
        z-index: 1000;
    }
    #templatemo_menu ul {
        margin: 0;
        padding: 0 40px 0 0;
        list-style: none;
        width: 100px;
        float: right;
        background: antiquewhite;
    }
    #templatemo_menu ul li {
        padding: 0;
        margin: 0;
        display: block;
        float: left;
        width: 164px;
        height: 45px;
        background: none;
    }
    #templatemo_menu ul li a {
        float: left;
        display: block;
        width: 150px;
        height: 75px;
        line-height: 75px;
        padding-right: 4px;
        font-size: 16px;
    }
    #templatemo_menu ul li a:hover {
        background: url(images/templatemo_menu_hover.png) no-repeat
    }
    #templatemo_menu .last {
        background: none
    }


    div#templatemo_header {
        clear: both;
        width: 100%;
        height: 170px;
        background: url(images/templatemo_header.png) no-repeat center top
    }

div#templatemo_header div#site_title {
    float: none;
    width: 300px;
    height: 170px;
    margin: 0 auto;
}


/*on device smallewr than 768px set font-size to 1.5em*/
@media screen and (max-width: 768px) {
    body {
        font-size: 1.5em;
    }
}


